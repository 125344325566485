import React, { useEffect, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Controller,
  Keyboard,
  Mousewheel,
  Navigation,
} from "swiper";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";

import useMediaQuery from "../../hooks/useMediaQuery";

import * as styles from "./ImageAccordion.module.scss";

const ImageAccordion = ({ accordionItems, className }) => {
  const [activeItem, setActiveItem] = useState(0);
  const [imageSwiper, setImageSwiper] = useState(null);
  const [textSwiper, setTextSwiper] = useState(null);

  useEffect(() => {
    accordionItems.forEach((item) => {
      const img = new Image();
      img.src = item.image.props.src;
    });
  }, []);

  const isMobile = useMediaQuery("(max-width: 1280px)");

  return !isMobile
    ? (
      <div className="grid grid-cols-12 gap-x-60 mx-18">
        <div className="col-span-6 lg:col-span-7 lg:self-center z-10">
          {accordionItems[activeItem].image}
        </div>
        <div className="col-span-6 lg:col-span-4">
          <div>
            {accordionItems.map((item, index) => (
              <div
                key={`accordion${item.title}`}
                className={classNames(
                  "py-4 cursor-pointer",
                  {
                    [styles.swiperActiveSide]: activeItem === index,
                    "text-black": activeItem === index,
                    "text-dark-grey": activeItem !== index,
                  },
                )}
                onClick={() => {
                  setActiveItem(index);
                }}
                role="presentation"
              >
                <div className={classNames((activeItem === index ? className : "text-grey"), "mb-6 text-body1")}>{item.tag}</div>
                <h4 className={classNames((activeItem === index ? className : "text-grey"), "h4 mb-9")}>
                  {item.title}
                </h4>
                <div className={classNames((activeItem === index ? className : "text-grey"), activeItem === index ? "block" : "hidden")}>
                  {item.text}
                  {item.ctaPath && item.ctaTitle && (
                    <div className="mt-10">
                      <a href={item.ctaPath} title={item.ctaTitle} className={classNames((activeItem === index ? className : "text-grey"), "underline uppercase")}>
                        {item.ctaTitle}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
    : (
      <div className="mx-18">
        <Swiper
          slidesPerView={1}
          keyboard
          onSwiper={setImageSwiper}
          controller={{ control: textSwiper }}
          mousewheel={{
            forceToAxis: true,
            thresholdTime: 1000,
          }}
          modules={[Controller, Navigation, Mousewheel, Keyboard]}
        >
          {accordionItems.map((item) => (
            <SwiperSlide>
              <div>
                {item.image}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          className={styles.swiper}
          keyboard
          slidesPerView={1.2}
          onSwiper={setTextSwiper}
          controller={{ control: imageSwiper }}
          onSlideChange={(swiper) => setActiveItem(swiper.activeIndex)}
          mousewheel={{
            forceToAxis: true,
            thresholdTime: 1000,
          }}
          modules={[Controller, Navigation, Mousewheel, Keyboard]}
        >
          {accordionItems.map((item, index) => (
            <SwiperSlide>
              <div
                className={classNames("slide-content mb-30", { "text-black": index === 0 })}
              >
                <div className={classNames((activeItem === index ? className : "text-grey"), "mb-6 text-body1")}>{item.tag}</div>
                <h3 className={classNames((activeItem === index ? className : "text-grey"), "h4 mb-9 mr-18 break-words")}>
                  {item.title}
                </h3>
                <div>
                  <div className={classNames((activeItem === index ? className : "text-grey"), "mr-18")}>
                    {item.text}
                  </div>
                  <div className="mt-10">
                    {item.ctaPath && item.ctaTitle && (
                      <a href={item.ctaPath} title={item.ctaTitle} className={classNames((activeItem === index ? className : "text-grey"), "mt-10 underline uppercase")}>
                        {item.ctaTitle}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
};

ImageAccordion.propTypes = {
  accordionItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  className: PropTypes.string,
};

ImageAccordion.defaultProps = {
  className: "",
};

export default ImageAccordion;
