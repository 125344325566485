import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import HeaderComponent from "../../components/Header/Header";

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      locales: allLocale {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
      main: sanityDigitalArchiveNavigation(name: {eq: "DigitalesArchiv"}) {
        entries {
          internalLink {
            ... on SanityDigitalArchiveEntry {
              id
              title
              slug {
                current
              }
            }
          }
          headline
          _type
          href
          linkType
        }
      }
    }
`);

  const mainItems = [];

  if (data?.main?.entries) {
    data.main.entries.forEach((node) => {
      switch (node.linkType) {
        case "internal":
          if (node.internalLink) {
            mainItems.push((
              <li className="text-right hyphens-auto" key={node.internalLink.slug.current}>
                <a href={`/digitales-archiv/${node.internalLink.slug.current}`} className="h2 text-h2 hover:underline break-words">
                  {node.internalLink.title}
                </a>
              </li>
            ));
          }
          break;
        case "external":
          mainItems.push((<div>EXTERN</div>));
          break;
        default:
      }
    });
  }

  return (
    <HeaderComponent variant="secondary">
      <ul className="grid gap-y-30 break-words">
        {mainItems}
      </ul>
    </HeaderComponent>
  );
};

export default Header;
