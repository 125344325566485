import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import ImageAccordion from "../../components/ImageAccordion/ImageAccordion";
import TextHeader from "../../components/TextHeader/TextHeader";
import DigitalArchiveHeader from "../layouts/DigitalArchiveHeader";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";
import GetCallToActionContext from "../utils/GetCallToActionContext";

const DigitalArchiveOverviewPage = ({ data: { page } }) => (
  <MainLayout header={<DigitalArchiveHeader />} variant="secondary">
    <TextHeader title={page.title}>
      {page._rawQuickInfoText && (
        <BlockContent
          blocks={page._rawQuickInfoText}
        />
      )}
    </TextHeader>
    <div>
      <ImageAccordion
        className=" text-white"
        accordionItems={
          page.entries?.elements?.map((entries) => ({
            ctaPath: GetCallToActionContext(entries?.callToAction).path,
            ctaTitle: entries?.callToAction.title,
            image: (
              entries.image?.asset && (
                <SanityImage
                  {...entries.image}
                  width={800}
                  layout="fullWidth"
                  className="lg:aspect-[745/548] aspect-[315/231] w-full h-auto"
                />
              )
            ),
            tag: entries.tag,
            text: entries.text,
            title: entries.headline,
          }))
        }
      />
    </div>
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityDigitalArchiveOverview(id: {eq: $id}) {
      id
      title
      _rawQuickInfoText
      entries {
        elements {
          text
          headline
          tag
          image {
            ...ImageWithPreview
            alt
          }
          callToAction {
            linkType
            blank
            title
            href
            _rawInternalLink(resolveReferences: {maxDepth: 5})
          }
        }
      }
    }
  }
`;

DigitalArchiveOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default DigitalArchiveOverviewPage;
